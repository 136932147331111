import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { environment } from 'src/environments/environment';
import { API_URL } from '../constants/api-url.constants';
import { LocalStorageService, StorageItem } from '../services/local-storage.service';
import { RxJsUtils } from '../utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLoggedIn = this.authService.isLoggedIn;

    if (isLoggedIn) {
      let token = this.localStorageService.getItem(StorageItem.AuthToken);
      if (request.url.includes(API_URL.account.refreshAccessToken)) {
        token = this.localStorageService.getItem(StorageItem.RefreshToken); // in refresh token API, we need to send refresh token as part of header.
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      if (request.url === `${API_URL.linkPreview.apiURL}/`) {
        request = request.clone({
          setHeaders: {
            'X-Linkpreview-Api-Key': API_URL.linkPreview.apiHeaderKey
          }
        });

        return next.handle(request);
      }
    }
    if (request.url.includes(API_URL.account.forgotPassword.finish)) {
      let token = this.localStorageService.getItem(StorageItem.AccessToken);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      }); // in refresh token API, we need to send refresh token as part of header.
    }

    request = request.clone({
      url: `${environment.apiUrl}/${request.url}`
    });
    const { urlWithParams } = request;

    return next.handle(request).pipe(RxJsUtils.shareDuplicate(urlWithParams));
  }
}
