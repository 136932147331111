<div class="footer">
  <div class="container-fluid">
    <div class="row">
      <div>
        <div class="float-start">
          <a (click)="goToDashboard()">
            <img [src]="constants.applicationLogoUrl" alt="Company Logo" class="footer-logo" />
          </a>
        </div>
        <div class="float-end">
          <div>
            <a class="text-decoration-none" [routerLink]="[path.root, path.footer.privacyPolicy]">Privacy Policy</a>
            <a [routerLink]="[path.root, path.footer.termsCondition]" class="px-3 text-decoration-none"
              >Terms & Conditions</a
            >
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="pb-2">
        <div class="float-start d-block fw-16 color-gray">&copy; copyright {{ currentYear }} All rights reserved.</div>
        <div class="d-block float-end">
          <ng-container *ngFor="let social of socialmedia">
            <a
              class="ms-5 f-18 color-gray"
              [href]="['' + social.root + social.subPath]"
              target="_blank "
              rel="noopener noreferrer ">
              <i [attr.Class]="social.icon" [attr.alt]="social.name"></i>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
