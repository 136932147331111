export const Socialmedia = [
  {
    name: 'X',
    icon: 'fa-brands fa-x-twitter',
    root: 'https://twitter.com/',
    subPath: 'sunsbridge'
  },
  {
    name: 'facebook',
    icon: 'fa-brands fa-facebook-f',
    root: 'https://www.facebook.com/',
    subPath: 'profile.php?id=61557478081965'
  },
  {
    name: 'linkedin',
    icon: 'fa-brands fa-linkedin-in',
    root: 'https://www.linkedin.com/',
    subPath: 'in/suns-bridge-b79b902ba'
  },
  {
    name: 'instagram',
    icon: 'fa-brands fa-instagram',
    root: 'https://www.instagram.com/',
    subPath: 'sunsbridge'
  }
];
