import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { UnauthorizedLayoutComponent } from './layout/unauthorized-layout/unauthorized-layout.component';
import { ROUTER_PATHS } from './shared/constants';
import { AuthGuard, NoAuthGuard } from './shared/guards';
import { AuthorizedLayoutFooterComponent } from './layout/authorized-layout/authorized-layout-footer/authorized-layout-footer.component';
import { PermissionGuard } from './shared/guards/permission.guard';

const routes: Routes = [
  { path: '', redirectTo: ROUTER_PATHS.dashboard.root, pathMatch: 'full' },
  {
    path: ROUTER_PATHS.auth.root,
    component: UnauthorizedLayoutComponent,
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
    canMatch: [() => inject(NoAuthGuard).canMatch()]
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    children: [
      {
        path: ROUTER_PATHS.dashboard.root,
        loadChildren: async () => (await import('./pages/dashboard/dashboard.module')).DashboardModule
      },
      {
        path: ROUTER_PATHS.contactUs.root,
        loadComponent: () => import('./pages/contact-us/contact-us.component').then((mod) => mod.ContactUsComponent)
      },
      {
        path: `${ROUTER_PATHS.footer.privacyPolicy}`,
        loadComponent: async () =>
          (await import('./layout/authorized-layout/footer/privacy-policy/privacy-policy.component'))
            .PrivacyPolicyComponent
      },
      {
        path: `${ROUTER_PATHS.footer.termsCondition}`,
        loadComponent: async () =>
          (await import('./layout/authorized-layout/footer/terms-condition/terms-condition.component'))
            .TermsConditionComponent
      }
    ]
  },
  {
    path: '',
    component: AuthorizedLayoutFooterComponent,
    canMatch: [() => inject(AuthGuard).canMatch()],
    children: [
      {
        path: ROUTER_PATHS.users.root,
        loadChildren: async () => (await import('./pages/user-management/user-management.module')).UserManagementModule
      },
      {
        path: ROUTER_PATHS.profile.changePassword,
        loadComponent: async () =>
          (await import('./pages/profile/account-settings/change-password/change-password.component'))
            .ChangePasswordComponent
      },
      {
        path: ROUTER_PATHS.taxCredit.taxCredit,
        loadComponent: async () => (await import('./pages/tax-credit/tax-credit.component')).TaxCreditComponent,
        data: { isSellHistory: false }
      },
      {
        path: ROUTER_PATHS.profile.root,
        loadChildren: async () => (await import('./pages/profile/profile.module')).ProfileModule
      },
      {
        path: `${ROUTER_PATHS.admin.root}/${ROUTER_PATHS.manageTaxCredit.root}`,
        canActivate: [PermissionGuard],
        loadChildren: async () =>
          (await import('./pages/manage-tax-credit/manage-tax-credit.module')).ManageTaxCreditModule
      },
      {
        path: ROUTER_PATHS.history.root,
        loadChildren: async () => (await import('./pages/history/history.module')).HistoryModule
      },
      {
        path: ROUTER_PATHS.history.mySellOrder,
        loadComponent: async () =>
          (await import('./pages/my-sell-orders/my-sell-orders.component')).MySellOrdersComponent
      },
      {
        path: `${ROUTER_PATHS.error.notFound}`,
        pathMatch: 'full',
        loadComponent: async () =>
          (await import('./pages/page-not-found/page-not-found.component')).PageNotFoundComponent
      },
      {
        path: `**`,
        redirectTo: `${ROUTER_PATHS.error.notFound}`
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
