<app-header *ngIf="!layout.isSidebarOnly"></app-header>
<div class="content container-fluid logged-in flex-1 flex-child pb-3 card-min-height">
  <router-outlet></router-outlet>
  <p-scrollTop
    target="parent"
    [styleClass]="layout.showFooter ? 'scrolltop-position' : ''"
    [threshold]="100"
    icon="pi pi-arrow-up"></p-scrollTop>
</div>
<app-footer *ngIf="layout.showFooter"></app-footer>
