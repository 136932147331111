export const ROUTER_PATHS = {
  root: '/',
  auth: {
    root: 'auth',
    login: 'login',
    forgotPassword: {
      root: 'forgot-password',
      init: 'init',
      finish: ':resetKey'
    },
    singUp: 'signup'
  },
  dashboard: {
    root: 'dashboard'
  },
  contactUs: {
    root: 'contactUs'
  },
  users: {
    root: 'users',
    list: 'list'
  },
  profile: {
    root: 'profile',
    changePassword: 'change-password'
  },
  error: {
    notFound: 'not-found'
  },
  footer: {
    termsCondition: 'terms-condition',
    privacyPolicy: 'privacy-policy'
  },
  taxCredit: {
    taxCredit: 'tax-credit'
  },
  history: {
    root: 'history',
    sellHistory: 'sell-history',
    buyHistory: 'buy-history',
    mySellOrder: 'sell-order'
  },
  admin: {
    root: 'admin'
  },
  manageTaxCredit: {
    root: 'manage-tax-credit',
    activeTaxCredit: 'active-credit',
    closeTaxCredit: 'close-credit',
    expiredTaxCredit: 'expire-credit'
  }
};
