<div
  *ngIf="layout.isSidebarOnly"
  class="header-container"
  [ngClass]="toggleDirection === toggleDirections.LEFT ? 'justify-content-between' : 'flex-row-reverse'">
  <div class="header-content">
    <div class="w-100">
      <p-button icon="pi pi-bars" styleClass="sidebar-toggle-btn" (click)="showSidebar = true"></p-button>
    </div>
  </div>
  <div>
    <app-header-elements [class.me-2]="toggleDirection === toggleDirections.RIGHT"></app-header-elements>
  </div>
</div>

<p-sidebar
  [(visible)]="showSidebar"
  position="left"
  [showCloseIcon]="true"
  [position]="toggleDirection"
  styleClass="custom-sidebar"
  (onHide)="onSidebarClosed()">
  <ng-template pTemplate="header">
    <div class="ms-2">
      <img [src]="constants.applicationLogoUrl" alt="Company Logo" class="logo" />
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="menu-items-container">
      <div *ngFor="let menuItem of menuItems">
        <div
          *ngIf="menuItem.isAccessible && !menuItem?.subMenuOptions?.length"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="active-route"
          class="menu-item"
          (click)="onSidebarClosed()"
          appItemHover>
          <div><i [class]="menuItem.icon"></i></div>
          <div>{{ menuItem.title }}</div>
        </div>
        <div *ngIf="menuItem.isAccessible && menuItem?.subMenuOptions?.length">
          <p-accordion
            class="w-full"
            [styleClass]="
              currentActiveParentRoute === menuItem.id ? 'parent-route-sidebar-active' : 'parent-route-sidebar'
            ">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <div>
                  <i [class]="menuItem.icon"></i>
                  {{ menuItem.title }}
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <div *ngFor="let subMenuItem of menuItem.subMenuOptions">
                  <div
                    *ngIf="subMenuItem.isAccessible"
                    [routerLink]="subMenuItem.routerLink"
                    routerLinkActive="active-route"
                    class="sub-menu-item"
                    (click)="onSidebarClosed()">
                    {{ subMenuItem.title }}
                  </div>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <div *ngIf="!isLoggedIn" class="my-auto">
        <button class="btn btn-outline-primary" (click)="signIn()">Login</button>
        <button class="btn btn-primary ms-2" (click)="signUp()">Sign up</button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div *ngIf="layout.showFooter" class="sidebar-footer">&copy; Sunflower Lab</div>
  </ng-template>
</p-sidebar>
