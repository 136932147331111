import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Account } from 'src/app/auth/models/user.model';
import { Constants, LAYOUT_SCHEME, PROGRESS_TYPES, ROUTER_PATHS } from '../../constants';
import { faIcons } from '../../constants/font-awesome-icons.constant';
import { Page, PaginationConfig } from '../../models';
import { THEMES } from '../../services/theme.service';
import { NavigationService } from '../../services';
import { Labels } from '../../constants/lable-constan';
import { SidebarName } from '../../constants/sidebar-name.constant';

@Component({
  selector: 'app-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.Default
})
export class BaseComponent implements OnDestroy {
  destroy$: Subject<void> = new Subject();
  paginationConfig: PaginationConfig = new PaginationConfig();
  sidebarNameList = SidebarName;
  currentUser!: Account | null;
  path = ROUTER_PATHS;
  pageTitle!: string;
  constants = Constants;
  faIcons = faIcons;
  THEMES = THEMES;
  layout = LAYOUT_SCHEME;
  progressTypes = PROGRESS_TYPES;
  labels = Labels;
  iconClass = this.constants.iconClass;
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setPaginationParamsFromPageResponse<T>(pageResponse: Page<T>) {
    this.paginationConfig.totalElements = pageResponse.totalElements;
    this.paginationConfig.itemsPerPage = pageResponse.numberOfElements;
    this.paginationConfig.pageNumber = pageResponse.number;
    this.paginationConfig = { ...this.paginationConfig };
  }
}
