<app-splash-screen></app-splash-screen>
<div
  [ngClass]="{
    'mobile-screen': deviceInfoModel.isMobile,
    'tablet-screen': deviceInfoModel.isTablet,
    'desktop-screen': deviceInfoModel.isDesktop
  }">
  <router-outlet></router-outlet>
</div>
<p-toast
  [baseZIndex]="5000"
  [position]="deviceInfoModel.isMobile ? 'bottom-center' : 'bottom-right'"
  [preventOpenDuplicates]="true"></p-toast>
<p-confirmDialog></p-confirmDialog>

<p-dialog
  header="New Version Update"
  [(visible)]="showSwUpdateDialog"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '70vw', position: 'fixed', bottom: '40px' }"
  styleClass="p-box">
  The newer version of the application is available now.
  <a (click)="closeSwDialog()">Click here</a> to download it now
</p-dialog>
