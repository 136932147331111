export const brandImage = [
  {
    name: 'Google',
    image: 'assets/images/brand/google.svg',
    root: '',
    subPath: ''
  },
  {
    name: 'Black Rock',
    image: 'assets/images/brand/black-rock.svg',
    root: '',
    subPath: ''
  },
  {
    name: 'cash app',
    image: 'assets/images/brand/cash-app.svg',
    root: '',
    subPath: ''
  },
  {
    name: 'meta',
    image: 'assets/images/brand/meta.svg',
    root: '',
    subPath: ''
  },
  {
    name: 'Moblize',
    image: 'assets/images/brand/mobilize.svg',
    root: '',
    subPath: ''
  }
];
