import { inject } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { CanActivateFn } from '@angular/router';

export enum Authorities {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN'
}

export const PermissionGuard: CanActivateFn = (route, state) => {
  return inject(PermissionService).canActivate(state);
};
