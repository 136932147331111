import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER_PATHS } from '../constants';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private readonly router: Router) {}

  navigateToDashboard(): void {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.dashboard.root]);
  }

  navigate(commands: [], navigationExtras?: { [key: string]: any } | undefined): Promise<boolean> {
    return this.router.navigate(commands, navigationExtras);
  }

  login(): void {
    this.router.navigate([ROUTER_PATHS.auth.login]);
  }

  signUp(): void {
    this.router.navigate([ROUTER_PATHS.auth.root, ROUTER_PATHS.auth.singUp]);
  }

  contactUs(): void {
    this.router.navigate([ROUTER_PATHS.contactUs.root]);
  }

  privacyPolicy(): void {
    this.router.navigate([ROUTER_PATHS.footer.privacyPolicy]);
  }

  termsCondition(): void {
    this.router.navigate([ROUTER_PATHS.footer.termsCondition]);
  }

  forgotPasswordFinish(resetKey: string): void {
    this.router.navigate([ROUTER_PATHS.auth.root, ROUTER_PATHS.auth.forgotPassword.root, resetKey]);
  }

  textCredit(): void {
    this.router.navigate([ROUTER_PATHS.taxCredit.taxCredit]);
  }
}
