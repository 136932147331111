<img
  [src]="currentUser?.imageUrl ?? ''"
  [appImageLoader]="constants.staticImages.loaders.imageLoader"
  [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
  alt="Profile image"
  class="profile-img pointer"
  (click)="accountOptions.toggle($event)" />

<p-overlayPanel styleClass="account-info-panel" #accountOptions>
  <ng-template pTemplate="content">
    <div class="profile-details-container" *ngIf="profileMenuType">
      <div>
        <img
          [src]="currentUser?.imageUrl ?? ''"
          [appImageLoader]="constants.staticImages.loaders.imageLoader"
          [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
          alt="Profile image"
          class="profile-img" />
      </div>
      <div class="ms-2">
        <div class="name">{{ currentUser?.firstName }} {{ currentUser?.lastName }}</div>
        <div class="email">{{ currentUser?.email }}</div>
      </div>
    </div>
    <div class="profile-option-wrapper">
      <div
        *ngFor="let option of allAccountOptions"
        [ngClass]="['options-container', option.class]"
        (click)="accountOptions.toggle($event)"
        [routerLink]="[option.route]"
        [routerLinkActive]="'active-option'"
        appItemHover>
        <i class="ps-1 img-hov" [class]="option.icon"></i>
        <span class="ps-2 f-16">{{ option.title }}</span>
      </div>
    </div>
    <div class="options-container">
      <p-divider></p-divider>
    </div>

    <div class="options-container text-danger logout-hover" (click)="confirmLogout()">
      <div
        class="d-inline"
        [appImageIconSrc]="constants.staticImages.icons.logout"
        [hoverImgSrc]="constants.staticImages.icons.logoutBlue"
        alt="logout-icon"></div>
      <span class="ms-2">Logout</span>
    </div>
  </ng-template>
</p-overlayPanel>
<!-- todo further fix -->
<!-- <div class="dock-window" *ngIf="dockWindowEnabled && isMobile && showDock">
  <p-dock [model]="items" [position]="position"> </p-dock>
</div> -->
<p-confirmDialog></p-confirmDialog>
