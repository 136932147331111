export const Labels = {
  name: 'Full Name',
  companyName: 'Company Name',
  phoneNo: 'Phone Number',
  email: 'Email',
  password: 'Password',
  ConformPassword: '',
  state: 'State',
  city: 'City',
  location: 'Location'
};
