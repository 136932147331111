import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageIconDirective, PasswordEyeDirective, ShowLoaderOnApiCallDirective } from '.';
import { DisableCopyPasteDirective } from './disable-copy-paste.directive';
import { ImageLoaderDirective } from './image-loader.directive';
import { ItemHoverDirective } from './item-hover.directive';

const DIRECTIVES = [
  PasswordEyeDirective,
  ImageIconDirective,
  ShowLoaderOnApiCallDirective,
  ImageLoaderDirective,
  ItemHoverDirective,
  DisableCopyPasteDirective
];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES]
})
export class DirectivesModule {}
