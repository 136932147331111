import { HttpBackend, HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from './base-crud.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UploadFileService extends BaseCrudService {
  getBaseAPIPath(): string {
    return ''; //TODO add endpoint for file upload here
  }

  constructor(private http: HttpClient) {
    //TODO remove this once BE API is available
    super(http);
    // super();
    // this.http = new HttpClient(handler);
  }

  uploadFile<UploadFileApiResponse>(
    endpoint: string,
    body: FormData,
    options?: any
  ): Observable<HttpEvent<UploadFileApiResponse>> {
    return this.httpClient.post<UploadFileApiResponse>(`${this.getFullAPIUrl(endpoint)}`, body, {
      ...options
    });
  }

  uploadFileToTestingServer(formData: FormData) {
    return this.http.post('https://api.imgbb.com/1/upload', formData, {
      reportProgress: true,
      observe: 'events',
      params: new HttpParams().set('key', '63b2578c8eff172b8c8c579180294e00')
    });
  }
}
