import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { ForgotPasswordOtpVerificationResponse, ServerMessage } from 'src/app/shared/models';
import { LocalStorageService, StorageItem } from 'src/app/shared/services/local-storage.service';
import { RefreshTokenService } from 'src/app/shared/services/refresh-token.service';
import {
  ForgotPasswordFinishRequestParams,
  ForgotPasswordInitRequestParams,
  LoginParams,
  LoginResponse
} from '../models';
import { Account } from '../models/user.model';
import { NavigationService } from '../../shared/services/navigation.service';
import { SignUpOtpActivate, SignupFormRequest, StateList } from '../models/singup.modal';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!this.localStorageService.getItem(StorageItem.AuthToken));
  currentUser!: Account;
  currentUserSubject: BehaviorSubject<Account> = new BehaviorSubject(this.currentUser);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly refreshTokenService: RefreshTokenService,
    private readonly localStorageService: LocalStorageService,
    private readonly navigationService: NavigationService
  ) {}

  login(loginParams: LoginParams): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(API_URL.account.login, loginParams).pipe(
      map((res) => {
        this.refreshTokenService.storeAuthTokens(res);
        this.refreshTokenService.startRefreshTokenTimer(res.access_token);
        this.isLoggedIn$.next(true);
        return res;
      })
    );
  }

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  onForgotPasswordInit(params: ForgotPasswordInitRequestParams): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(
      `${API_URL.account.root}/${API_URL.account.forgotPassword.root}/${API_URL.account.forgotPassword.init}`,
      params
    );
  }

  onForgotPasswordFinish(params: ForgotPasswordFinishRequestParams): Observable<void> {
    return this.httpClient.post<void>(
      `${API_URL.account.root}/${API_URL.account.forgotPassword.root}/${API_URL.account.forgotPassword.finish}`,
      params
    );
  }

  setCurrentUser$(value: Account) {
    this.currentUserSubject.next(value);
  }

  getCurrentUser$(): Observable<Account> {
    return this.currentUserSubject.asObservable();
  }

  getCurrentUserDetails(): Account {
    return this.currentUserSubject.getValue();
  }

  getCurrentUser(fromDb = false): Observable<Account | null> {
    if (fromDb || !this.currentUser?.id) {
      const storedUser = this.localStorageService.getItem(StorageItem.CurrentUser);

      if (storedUser) {
        this.currentUser = storedUser as Account;
        this.currentUserSubject.next(storedUser as Account);
      }

      return this.httpClient.get<Account>(API_URL.account.userDetail.profile).pipe(
        map((res) => {
          this.currentUser = res;
          this.currentUserSubject.next(res);
          this.localStorageService.setItem(StorageItem.CurrentUser, res);
          return res;
        }),
        catchError((err) => {
          this.logOut();
          return of(null);
        })
      );
    }

    return of(this.currentUser);
  }

  logOut(): void {
    this.localStorageService.removeItem(StorageItem.AuthToken);
    this.localStorageService.removeItem(StorageItem.CurrentUser);
    this.isLoggedIn$.next(false);
    this.refreshTokenService.stopRefreshTokenTimer();
    this.navigationService.navigateToDashboard();
    location.reload();
  }

  stateList(): Observable<Array<StateList>> {
    return this.httpClient.get<Array<StateList>>(API_URL.account.singUp.stateList);
  }

  singUp(params: SignupFormRequest): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(API_URL.account.singUp.signup, params);
  }

  otpValidate(params: SignUpOtpActivate, resend?: boolean): Observable<ServerMessage> {
    const url = resend ? API_URL.account.singUp.resendOtp : API_URL.account.singUp.otpActivate;
    return this.httpClient.post<ServerMessage>(url, params);
  }

  forgotPasswordOtpVerification(
    params: SignUpOtpActivate,
    resend?: boolean
  ): Observable<ForgotPasswordOtpVerificationResponse> {
    const prePath = `${API_URL.account.root}/${API_URL.account.forgotPassword.root}/`;
    const url = resend
      ? prePath + API_URL.account.forgotPassword.init
      : prePath + API_URL.account.forgotPassword.otpVerification;
    return this.httpClient.post<ForgotPasswordOtpVerificationResponse>(url, params).pipe(
      map((res) => {
        if (url.includes(API_URL.account.forgotPassword.otpVerification)) {
          this.localStorageService.setItem(StorageItem.AccessToken, res.accessToken);
          return res;
        }
        return res;
      })
    );
  }
}
