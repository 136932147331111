import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../components/base-component/base.component';

@Injectable({
  providedIn: 'root'
})
export class SeoService extends BaseComponent {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly metaService: Meta
  ) {
    super();
  }

  init(): void {
    const appTitle = this.titleService.getTitle();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getLatestChild().snapshot.data || {}),
        takeUntil(this.destroy$)
      )
      .subscribe(({ pageTitle, description, robots }) => {
        console.log(pageTitle, description, robots);
        this.setTitle(appTitle, pageTitle);
        this.setDescription(description);
        this.setRobots(robots);
      });
  }

  private getLatestChild(): ActivatedRoute {
    let child = this.activatedRoute.firstChild as ActivatedRoute;

    while (child.firstChild) {
      child = child.firstChild;
    }

    return child;
  }

  private setTitle(rootTitle: string, title: string): void {
    if (title) {
      this.titleService.setTitle(`${rootTitle} - ${title}`);
    }
  }

  private setDescription(description: string): void {
    if (description) {
      this.metaService.updateTag({
        property: 'description',
        content: description
      });
    }
  }

  private setRobots(robots: string): void {
    if (robots) {
      this.metaService.updateTag({
        property: 'robots',
        content: robots
      });
    }
  }
}
