import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services';
import { ServerMessage } from '../models';
import { AppToasterService, CommonService } from '../services';
@Injectable({ providedIn: 'root' })
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly toasterService: AppToasterService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.commonService.isApiCallInProgress$.next(true);
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.commonService.isApiCallInProgress$.next(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.commonService.isApiCallInProgress$.next(false);
        const errorResponse: ServerMessage = error.error;
        if (errorResponse.message) {
          if ([401].includes(error.status)) {
            this.toasterService.error(errorResponse.message);
            this.authService.logOut();
            return throwError(() => error);
          } else {
            this.toasterService.error(errorResponse.message);
            return throwError(() => error);
          }
        } else {
          this.toasterService.error('Something went wrong!');
          return throwError(() => error);
        }
      })
    );
  }
}
