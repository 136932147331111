import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService, StorageItem } from './local-storage.service';
import { Account } from 'src/app/auth/models/user.model';
import { ROUTER_PATHS } from '../constants';
import { Authorities } from '../guards/permission.guard';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private readonly route: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  canActivate(state: RouterStateSnapshot): boolean {
    const url = state.url;
    const user: Account = this.localStorageService.getItem(StorageItem.CurrentUser) as Account;
    if (user && url.includes(ROUTER_PATHS.admin.root) && user.authorities.includes(Authorities.ROLE_ADMIN)) {
      return true;
    }
    this.route.navigate([ROUTER_PATHS.error.notFound]);
    return false;
  }
}
