import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { CommonService } from 'src/app/shared/services';
import { ToggleDirections, MenuItem, MenuItems, GeneralUserPath, ContactUsPath, AdminUserPath } from './header.model';
import { NavigationService } from '../../../shared/services/navigation.service';
import { LocalStorageService, StorageItem } from '../../../shared/services/local-storage.service';
import { Authorities } from 'src/app/shared/guards/permission.guard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends BaseComponent {
  @Input() toggleDirection: ToggleDirections = ToggleDirections.LEFT;
  isLoggedIn = this.authService.isLoggedIn;
  currentActiveParentRoute!: string;

  menuItems: Array<MenuItem> = MenuItems;
  toggleDirections = ToggleDirections;
  showSidebar = false;
  showProgressBar = false;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly navigationService: NavigationService,
    private readonly localStorageService: LocalStorageService
  ) {
    super();
    this.setActiveRoute();
  }
  // todo further use
  ngOnInit(): void {
    this.getAccountDetails();
    this.setHeaderInit();
  }

  subscribeToAPICallProgress(): void {
    //Call this method to show global progress bar
    this.commonService.isApiCallInProgress$.pipe(takeUntil(this.destroy$)).subscribe((isApiCallInProgress) => {
      this.showProgressBar = isApiCallInProgress;
      this.cdr.detectChanges();
    });
  }

  getCurrentUser(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.currentUser = res;
          this.setHeaderByUser();
        }
      });

    this.cdr.detectChanges();
  }

  setHeaderByUser(): void {
    if (this.currentUser) {
      this.authService.setCurrentUser$(this.currentUser);
      this.localStorageService.setItem(StorageItem.CurrentUser, this.currentUser);
      if (this.currentUser.authorities.includes(Authorities.ROLE_ADMIN)) {
        this.menuItems = [...MenuItems, ...AdminUserPath];
      } else if (this.currentUser.authorities.includes(Authorities.ROLE_USER)) {
        this.menuItems = [...MenuItems, ...GeneralUserPath];
      }
      this.menuItems = [...this.menuItems, ...ContactUsPath];
      this.cdr.detectChanges();
    }
  }

  setHeaderInit(): void {
    if (!this.isLoggedIn) {
      this.menuItems = [...MenuItems, ...GeneralUserPath, ...ContactUsPath];
    }
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }

  signIn(): void {
    this.navigationService.login();
  }
  signUp(): void {
    this.navigationService.signUp();
  }

  goToDashboard(): void {
    this.navigationService.navigateToDashboard();
  }

  getAccountDetails(): void {
    this.authService.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        this.getCurrentUser();
      }
    });
  }
}
