import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { environment } from 'src/environments/environment';
import { Socialmedia } from '../../../shared/constants/social-media.constant';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends BaseComponent implements OnInit {
  currentYear = new Date().getFullYear();
  currentApplicationVersion!: string;
  buildDateTime!: string;
  socialmedia = Socialmedia;
  ngOnInit(): void {
    this.setFooterParams();
  }

  constructor(private readonly navigationService: NavigationService) {
    super();
  }

  setFooterParams(): void {
    this.currentApplicationVersion = `${environment.versions.version}-${environment.versions.revision}-${environment.versions.branch}`;
    this.buildDateTime = environment.versions.buildDateTime;
  }

  goToDashboard(): void {
    this.navigationService.navigateToDashboard();
  }
}
