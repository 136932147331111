import { AuthorizedLayoutFooterComponent } from './authorized-layout/authorized-layout-footer/authorized-layout-footer.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DockModule } from 'primeng/dock';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { ProfileInfoComponent } from '../pages/profile/profile-info/profile-info.component';
import { DirectivesModule } from '../shared/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { AuthorizedLayoutComponent } from './authorized-layout/authorized-layout.component';
import { FooterComponent } from './authorized-layout/footer/footer.component';
import { HeaderElementsComponent } from './authorized-layout/header-elements/header-elements.component';
import { HeaderComponent } from './authorized-layout/header/header.component';
import { SidebarComponent } from './authorized-layout/sidebar/sidebar.component';
import { UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component';

@NgModule({
  declarations: [
    UnauthorizedLayoutComponent,
    AuthorizedLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    HeaderElementsComponent,
    AuthorizedLayoutFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ButtonModule,
    SidebarModule,
    TooltipModule,
    OverlayPanelModule,
    AccordionModule,
    ConfirmDialogModule,
    ScrollTopModule,
    DirectivesModule,
    ProgressBarModule,
    DividerModule,
    DockModule,
    ProfileInfoComponent
  ],
  exports: [SidebarComponent],
  providers: [ConfirmationService]
})
export class LayoutModule {}
