import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationService, MessageService } from 'primeng/api';
import { filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from './shared/constants/app.constants';
import { DeviceInfoModel } from './shared/models/device-info.model';
import { CommonService, SeoService } from './shared/services';
import { LocalStorageService, StorageItem } from './shared/services/local-storage.service';
import { SplashScreenService } from './shared/services/splash-screen.service';
import { THEMES, ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  title = 'angularBaseProject_v3';
  showSwUpdateDialog: boolean = false;
  deviceInfoModel: DeviceInfoModel = new DeviceInfoModel();

  constructor(
    private readonly themeService: ThemeService,
    private readonly swUpdate: SwUpdate,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly splashScreenService: SplashScreenService,
    private readonly router: Router,
    private readonly seoService: SeoService,
    private readonly deviceService: DeviceDetectorService,
    private readonly commonService: CommonService,
    private readonly cdr: ChangeDetectorRef,
    private readonly localStorageStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const storedTheme = this.localStorageStorageService.getItem(StorageItem.Theme);
    this.checkVersionUpdate();
    this.initializeRouterEventListener();
    this.setTheme(storedTheme as THEMES);
    this.runGlobalServices();
    this.getDeviceInfo();
  }

  checkVersionUpdate(): void {
    if (environment.swUpdate) {
      if (environment.swUpdateFooter) {
        this.swUpdateDialog();
        return;
      }
      this.swUpdateConformationPopUp();
    }
  }

  initializeRouterEventListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, Constants.splashScreenTimeout);
      }
    });
  }

  setTheme(theme: THEMES): void {
    this.themeService.changeTheme(theme);
  }

  private runGlobalServices(): void {
    this.seoService.init();
  }

  swUpdateConformationPopUp(): void {
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion
      }))
    );
    updatesAvailable.subscribe((evt: any) => {
      this.confirmationService.confirm({
        message: 'A new version is available. Load the new version?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          window.location.reload();
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'You have accepted'
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Rejected',
            detail: 'You have rejected'
          });
        }
      });
    });
  }

  swUpdateDialog(): void {
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion
      }))
    );
    updatesAvailable.subscribe((evt: any) => {
      this.showSwUpdateDialog = true;
    });
  }

  closeSwDialog(): void {
    this.showSwUpdateDialog = false;
    window.location.reload();
  }

  getDeviceInfo(): void {
    this.deviceInfoModel.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfoModel.isMobile = this.deviceService.isMobile(); // mobile-screen
    this.deviceInfoModel.isTablet = this.deviceService.isTablet(); // tablet-screen
    this.deviceInfoModel.isDesktop = this.deviceService.isDesktop(); // desktop-screen
    this.cdr.detectChanges();
  }
}
