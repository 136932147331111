import { environment } from 'src/environments/environment';

export const Constants = {
  dockWindowEnabled: true,
  applicationLogoUrl: 'assets/images/logo.svg',
  applicationLogoDarkUrl: 'assets/images/logo.svg',
  environmentsForErrorTracing: ['localhost', environment.hostUrl],
  splashScreenTimeout: 200,
  dateFormat: 'MM/dd/yyyy',
  pattern: {
    PHONE_NUMBER_PATTERN: new RegExp(/^(?:(?:\+|0{0,2})91[\s-]?)?(?:(?:\(\d{1,3}\))|\d{1,3})[\s-]?\d{4}[\s-]?\d{3,4}$/),
    PASSWORD_PATTERN: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
  },
  staticImages: {
    icons: {
      dashboardActive: 'assets/images/icons/Dashboard-Active.svg',
      dashboardHover: 'assets/images/icons/Dashboard-Hover.svg',
      lockPassword: 'assets/images/icons/Lock-Password.svg',
      loginUser: 'assets/images/icons/Login-User.svg',
      addNew: 'assets/images/icons/Add-New.svg',
      quote: 'assets/images/quote.svg',
      lock: 'assets/icons/lock.svg',
      logout: 'assets/icons/logout.svg',
      logoutBlue: 'assets/icons/logout-blue.svg'
    },
    images: {
      profileImgPlaceholder: 'assets/images/Profile.svg',
      pageNotFound: 'assets/images/page-not-found.svg',
      dashboardOverlay: ' assets/images/tax-credit-screen-shot.svg',
      curvePath: '/assets/images/curve-graphics.svg',
      profileBackground: 'assets/images/white-cubes-3d-background.svg',
      profile: '/assets/images/Profile.svg',
      solarImage: 'assets/images/solar-image.jpg',
      imageUploadIcon: 'assets/images/image-upload-icon.svg',
      success: 'assets/images/image-upload-icon.svg',
      bulb: 'assets/images/bulb.svg',
      bid: 'assets/images/bid.svg',
      calender: 'assets/images/calender.svg',
      dolor: 'assets/images/dolor.svg',
      percentage: 'assets/images/percentage.svg',
      lock: 'assets/images/lock.svg',
      location: 'assets/images/location.svg',
      deleteTaxCredit: 'assets/images/delete.svg',
      purchaseDone: 'assets/images/purchase-done.svg',
      dummyImage: 'assets/images/dummy-image.svg',
      edit: 'assets/images/edit.svg',
      windImage: 'assets/images/wind.jpg',
      gasImage: 'assets/images/biogas.jpg'
    },
    testimonialImage: {
      testimonialImage1: 'assets/images/profile.png'
    },
    loaders: {
      imageLoader: 'assets/loaders/image-loader.gif'
    }
  },
  timeZone: {
    Est: 'America/New_York'
  },
  successMessages: {
    changePassword: 'Password updated successfully',
    imageCompression: 'Image compression is complete successfully',
    deleteUser: 'User Deleted successfully',
    theme: 'Theme updated successfully',
    otpSend: 'otp Send Success Fully',
    registrationSuccess: 'Registration done successfully',
    success: 'Success'
  },
  infoMessages: {
    imageCompression: 'No compression needed. Image size is within the limit.'
  },
  errorMessages: {
    email: 'Email is undefined'
  },
  otpConfig: {
    length: 6,
    allowNumbersOnly: true,
    isPasswordInput: false
  },
  passwordValidationMessage: {
    uppercase: 'Uppercase letters: A-Z.',
    lowerCase: 'Lowercase letters: a-z.',
    Numbers: 'Numbers: 0-9.',
    symbol: `Symbols: @,#,$,%,&,*,!`
  },
  otpTimeout: {
    otpTime: 60
  },
  iconClass: {
    map: 'pi pi-map-marker',
    phoneNo: 'pi pi-phone',
    envelop: 'pi pi-envelope',
    profile: 'pi pi-user',
    building: 'pi pi-building'
  },
  imageFormat: ['image/png', 'image/jpeg', 'image/jpg'],
  imageFormatDataString: '.png, .jpg, .jpeg',
  responsiveOption: [
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ],
  Headers: {
    noOfBid: 'No of Bid',
    noOfBuy: 'No of Buy Now',
    requestInfo: 'Request Info user'
  },
  pagination: [10, 20, 30],
  qualifiedMinimumValue: 100,
  nullValueInTale: '0'
};

export const LAYOUT_SCHEME = {
  isSidebarOnly: false,
  showFooter: true
};

export enum PROGRESS_TYPES {
  spinner = 'spinner',
  progressBar = 'progressBar'
}
