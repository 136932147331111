export const API_URL = {
  account: {
    root: 'account',
    login: 'authenticate',
    refreshAccessToken: 'token/refresh',
    forgotPassword: {
      root: 'reset-password',
      init: 'init',
      finish: 'finish',
      otpVerification: 'verification'
    },
    singUp: {
      signup: 'register',
      stateList: 'states',
      otpActivate: 'activate',
      resendOtp: 'send'
    },
    changePassword: 'change-password',
    userDetail: {
      profile: 'getUser',
      updateUserProfile: 'suns-bridge-user',
      profileUpload: 'file/sync',
      file: 'file'
    },
    contactUs: 'contact-us'
  },
  technologyType: 'technology-type',
  users: {
    root: '',
    paginatedList: 'paginated-sfl-users',
    action: 'sfl-users',
    user: 'getUser'
  },
  firebaseSubscription: {
    subscribe: 'subscribe',
    unsubscribe: ''
  },
  linkPreview: {
    apiURL: 'https://api.linkpreview.net',
    apiHeader: 'X-Linkpreview-Api-Key',
    apiHeaderKey: 'd3e4b7dddba1dfd87d2f3c7aa4f79fc6'
  },
  taxCredit: {
    createTaxCredit: 'tax-credit',
    taxCreditTable: 'tax-credit',
    bid: 'bid',
    manage: 'manage',
    closeDeal: 'bid',
    requestInformation: 'request-information'
  }
};
