import { Constants } from './app.constants';
import { ROUTER_PATHS } from './router.constants';

export const MenuConfig = [
  {
    name: 'Dashboard',
    iconPath: Constants.staticImages.icons.dashboardActive,
    hoverIconPath: Constants.staticImages.icons.dashboardHover,
    routerLink: ROUTER_PATHS.dashboard.root,
    children: []
  },
  {
    name: 'Administration',
    iconPath: 'Constants.staticImages.icons.adminActive',
    hoverIconPath: 'Constants.staticImages.icons.adminHover',
    routerLink: 'ROUTER_PATHS.config.administration.root',
    children: [
      {
        name: 'Users',
        routerLink: ['ROUTER_PATHS.config.administration.root, ROUTER_PATHS.config.administration.users.root'],
        iconPath: Constants.staticImages.icons.loginUser,
        hoverIconPath: Constants.staticImages.icons.loginUser
      }
    ]
  }
];
