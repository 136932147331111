<div
  class="header-container"
  [ngClass]="
    toggleDirection === toggleDirections.LEFT
      ? 'justify-content-between'
      : 'flex-md-row flex-row-reverse justify-content-md-between'
  ">
  <div class="d-flex header-content">
    <div class="d-none d-md-block align-self-center">
      <a (click)="goToDashboard()">
        <img [src]="constants.applicationLogoUrl" alt="Company Logo" class="logo" />
      </a>
    </div>
    <div class="d-block d-md-none w-100">
      <p-button icon="pi pi-bars" styleClass="sidebar-toggle-btn" (click)="showSidebar = true"></p-button>
    </div>
    <div class="d-none d-md-flex align-items-md-center menu-items-container ms-2">
      <div *ngFor="let menuItem of menuItems">
        <div
          *ngIf="menuItem.isAccessible && !menuItem?.subMenuOptions?.length"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="active-route"
          class="menu-item"
          (click)="showSidebar = false"
          appItemHover>
          <i [class]="menuItem.icon"></i>
          <span class="ms-1">{{ menuItem.title }}</span>
        </div>
        <div *ngIf="menuItem.isAccessible && menuItem?.subMenuOptions?.length" class="d-none d-md-block" appItemHover>
          <div
            class="menu-item"
            [ngClass]="{
              'active-route': currentActiveParentRoute === menuItem.id
            }"
            (click)="childMenuOptions.toggle($event)">
            <i [class]="menuItem.icon"></i>
            <span class="ms-1">{{ menuItem.title }}</span>
            <i class="pi pi-chevron-down parent-route-chevron"></i>
          </div>
          <p-overlayPanel styleClass="header-sub-menu" #childMenuOptions>
            <ng-template pTemplate="content">
              <div *ngFor="let subMenuItem of menuItem.subMenuOptions">
                <div
                  *ngIf="subMenuItem.isAccessible"
                  [routerLink]="subMenuItem.routerLink"
                  routerLinkActive="active-sub-route"
                  class="sub-menu-item"
                  (click)="childMenuOptions.hide()">
                  <i [class]="subMenuItem.icon"></i>
                  <span class="ms-1">{{ subMenuItem.title }}</span>
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoggedIn" class="my-auto d-none d-md-block me-sm-2">
      <button class="btn btn-outline-primary" (click)="signIn()">Login</button>
      <button class="btn btn-primary ms-2" (click)="signUp()">Sign up</button>
    </div>
    <div *ngIf="isLoggedIn" class="my-auto">
      <app-header-elements [class.me-2]="toggleDirection === toggleDirections.RIGHT"></app-header-elements>
    </div>
  </div>
</div>
<div *ngIf="showProgressBar" class="progress-bar-container">
  <p-progressBar mode="indeterminate" [style]="{ height: '2px' }"></p-progressBar>
</div>

<app-sidebar
  class="d-block d-md-none"
  [showSidebar]="showSidebar"
  [currentActiveParentRoute]="currentActiveParentRoute"
  [toggleDirection]="toggleDirection"
  (onClose)="showSidebar = false"></app-sidebar>
