import { CommonModule } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ASPECT_RATIOS, ImageCropperModel } from 'src/app/shared/components/crop-image/crop-image.model';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { UploadFileService } from 'src/app/shared/services/upload-file.service';

const DEPENDENCIES = {
  MODULES: [CommonModule, CardModule, SharedComponentsModule, DirectivesModule]
};

@Component({
  standalone: true,
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  imports: [...DEPENDENCIES.MODULES]
})
export class ProfileInfoComponent extends BaseComponent implements OnInit {
  @Input() isImageUpload!: Boolean;
  @Input() horizontalProfileMenu!: Boolean;
  imageCropper!: ImageCropperModel;
  profileOptions!: MenuItem[];
  activeOption!: MenuItem;
  uploadPercentage = 0;
  isUploading = false;
  constructor(
    private readonly authService: AuthService,
    private readonly uploadFileService: UploadFileService
  ) {
    super();
    this.profileOptions = [
      { id: 'personal-info', label: 'Personal Info' },
      { id: 'change-password', label: 'Change Password' },
      { id: 'theme', label: 'Theme' }
    ];
    this.activeOption = this.profileOptions[0];
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
  }

  subscribeToCurrentUser(): void {
    this.authService.currentUserSubject.subscribe((val) => {
      this.currentUser = val || { firstName: '', lastName: '', email: '' };
    });
  }

  onFileSelected($event: any): void {
    if ($event.target.files && $event.target.files[0]) {
      const selectedFile = $event.target.files[0];
      this.imageCropper = new ImageCropperModel({
        imageChangedEvent: $event,
        isCropperVisible: true,
        roundCropper: true,
        aspectRatio: ASPECT_RATIOS.square,
        maintainAspectRatio: true,
        outputHeight: 300,
        showAspectRatio: false,
        showAspectRatioSelection: false,
        containWithinAspectRatio: false,
        name: selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'))
      });
    }
  }

  onActiveItemChange($event: MenuItem): void {
    this.activeOption = $event;
  }

  saveProfileImage(file: File): void {
    this.isUploading = true;
    const formData = new FormData();
    formData.append('image', file, file.name);
    //TODO uncomment when API endpoint is finalized.
    // this.uploadFileService.uploadFile('', formData).subscribe({
    //   next: (res: any) => {
    //     if (res && this.currentUser) {
    //       this.currentUser.imageUrl = res.url;
    //     }
    //   },
    // });

    //Open API for file upload
    this.uploadFileService.uploadFileToTestingServer(formData).subscribe((res: any) => {
      if (res.type === HttpEventType.Response) {
        if (res && this.currentUser) {
          this.currentUser.imageUrl = res.body.data.image.url;
        }
        this.isUploading = false;
      }
      if (res.type === HttpEventType.UploadProgress) {
        this.uploadPercentage = Math.round((100 * res.loaded) / res.total);
      }
    });
  }

  clearFiles($event: any): void {
    $event.target.value = null;
  }
}
