<p-card class="personal-info-card" [ngClass]="{ 'personal-info-card2': !horizontalProfileMenu }">
  <div
    class="profile-img-container"
    [ngClass]="{ 'profile-img-container-vertical': !horizontalProfileMenu }"
    [class.hover-container]="!isUploading && !isImageUpload">
    <img
      [src]="currentUser?.imageUrl ?? ''"
      [appImageLoader]="constants.staticImages.loaders.imageLoader"
      [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
      alt="Profile Image"
      class="profile-img" />
    <div class="change-profile-img-container" *ngIf="isImageUpload">
      <i class="pi pi-camera"></i>
    </div>
    <div class="select-file" *ngIf="isImageUpload">
      <input
        id="fileInput"
        type="file"
        class="w-100 h-100"
        accept="image/*"
        (change)="onFileSelected($event)"
        (click)="clearFiles($event)"
        [disabled]="isUploading" />
    </div>
    <app-upload-progress
      *ngIf="isUploading && isImageUpload"
      [progressType]="progressTypes.spinner"
      [uploadPercentage]="uploadPercentage"></app-upload-progress>
  </div>
  <div class="text-center mt-2 mt-md-4">
    <h4 [ngClass]="{ 'verticalProfileMenu-Fonts': !horizontalProfileMenu }">
      {{ currentUser?.firstName + " " + currentUser?.lastName | titlecase }}
    </h4>
    <div class="email-container" [ngClass]="{ 'verticalProfileMenu-Fonts': !horizontalProfileMenu }">
      <i class="pi pi-envelope"></i>
      <div class="ms-2">{{ currentUser?.email }}</div>
    </div>
  </div>
</p-card>
<app-crop-image
  *ngIf="imageCropper"
  [imageCropper]="imageCropper"
  [compressAfterFileSize]="5"
  (croppedImage)="saveProfileImage($event)"></app-crop-image>
