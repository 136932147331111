import { Component } from '@angular/core';
import { LAYOUT_SCHEME } from 'src/app/shared/constants';

@Component({
  selector: 'app-authorized-layout-footer',
  templateUrl: './authorized-layout-footer.component.html',
  styleUrl: './authorized-layout-footer.component.scss'
})
export class AuthorizedLayoutFooterComponent {
  layout = LAYOUT_SCHEME;
}
