import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { Constants } from 'src/app/shared/constants';
import { DeviceInfoModel } from 'src/app/shared/models/device-info.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-elements',
  templateUrl: './header-elements.component.html',
  styleUrls: ['./header-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderElementsComponent extends BaseComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription | undefined;
  position!: 'bottom' | 'top' | 'left' | 'right';
  items: MenuItem[] | undefined;
  dockWindowEnabled = Constants.dockWindowEnabled;
  deviceInfoModel: DeviceInfoModel = new DeviceInfoModel();
  isMobile = false;
  showDock = false;

  allAccountOptions = [
    {
      title: 'My Profile',
      icon: 'pi pi-user',
      class: 'pb-2',
      route: 'profile'
    },
    {
      title: 'My sell Orders',
      icon: 'pi pi-shopping-bag',
      class: 'pb-2',
      route: this.path.history.mySellOrder
    },
    {
      title: 'Change password',
      icon: 'pi pi-lock',
      class: 'pb-2',
      image: this.constants.staticImages.icons.lock,
      route: this.path.profile.changePassword
    }
  ];

  profileMenuType = false;
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly confirmationService: ConfirmationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly deviceService: DeviceDetectorService
  ) {
    super();
    this.profileMenuType = environment.horizontalProfileMenu;
    this.deviceInfoModel.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.observeScreenSize();
    this.position = 'bottom';
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.showDock) {
        this.toggleDock();
      }
    });

    this.items = [
      { icon: 'pi pi-user', label: 'Profile', command: () => this.router.navigateByUrl('/profile/personal-info') },
      { icon: 'pi pi-sign-out', label: 'Logout', style: [{ color: 'red' }], command: () => this.authService.logOut() }
    ];
  }

  toggleDock() {
    this.showDock = !this.showDock;
  }

  subscribeToCurrentUser(): void {
    this.authService.currentUserSubject.subscribe((val) => {
      this.currentUser = val;
      this.cdr.detectChanges();
    });
  }

  observeScreenSize(): void {
    this.isMobile = this.deviceService.isMobile();
    this.cdr.detectChanges();
  }

  confirmLogout(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to logout from the application?',
      header: 'Logout',
      icon: 'pi pi-sign-out',
      acceptButtonStyleClass: 'p-button-danger p-button-raised',
      defaultFocus: 'reject',
      acceptIcon: 'pi pi-sign-out me-2',
      acceptLabel: 'Logout',
      rejectButtonStyleClass: 'p-button-text shimmer btn-focus',
      rejectLabel: 'No',
      rejectIcon: 'pi pi-times me-2',
      accept: () => {
        this.logout();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  logout(): void {
    this.authService.logOut();
  }

  override ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
