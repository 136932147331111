import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChartViewerComponent } from './chart-viewer/chart-viewer.component';
import { CropImageComponent } from './crop-image/crop-image.component';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { UploadProgressComponent } from './upload-progress/upload-progress.component';

const COMPONENTS = [
  ErrorHandlerComponent,
  SplashScreenComponent,
  CropImageComponent,
  UploadProgressComponent,
  ChartViewerComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ImageCropperModule,
    DialogModule,
    ButtonModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  exports: [...COMPONENTS, NgxEchartsModule]
})
export class SharedComponentsModule {}
