export const SidebarName = {
  createTaxCredit: {
    createTaxCredit: 'createTaxCredit',
    createTaxHeaderName: 'Create Tax Credit'
  },
  buySuccess: {
    buySuccess: 'buySuccess',
    buySuccessHeaderName: 'Buy Success'
  },
  bidSucess: {
    bidSuccess: 'bidSuccess',
    bidSuccessHeaderName: 'Bid Success'
  },
  closeDealSucess: {
    closeDealSuccess: 'closeDealSuccess',
    closeDealSuccessHeaderName: 'Success'
  },
  taxCreditDetails: {
    taxCreditDetails: 'taxCreditDetails',
    taxCreditDetailsHeaderName: 'Details'
  },
  taxCreditBidNow: {
    taxCreditBidNow: 'taxCreditBidNow',
    taxCreditBidNowHeaderName: 'Bid Now'
  },
  mangeTaxCreditClient: {
    manageTaxCreditClient: 'manageTaxCreditClient',
    manageTaxCreditClientHeaderName: 'Manage'
  },
  deleteTaxCreditClient: {
    deleteTaxCreditClient: 'deleteTaxCredit',
    deleteTaxCreditClientHeaderName: 'delete Tax Credit'
  },
  closeTaxCreditClient: {
    closeTaxCreditClient: 'closeTaxCreditInfo',
    closeTaxCreditClientHeaderName: 'Details'
  },
  editTaxCreditClient: {
    editTaxCreditClient: 'closeTaxCreditInfo',
    editTaxCreditClientHeaderName: 'Details'
  }
};
