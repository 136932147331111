import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AdminUserPath, ContactUsPath, GeneralUserPath, MenuItems, ToggleDirections } from '../header/header.model';
import { NavigationService } from 'src/app/shared/services';
import { Authorities } from 'src/app/shared/guards/permission.guard';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends BaseComponent implements OnInit {
  isLoggedIn = this.authService.isLoggedIn;
  @Input() toggleDirection: ToggleDirections = ToggleDirections.LEFT;
  @Input() showSidebar = false;
  @Input() currentActiveParentRoute!: string;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);
  menuItems = MenuItems;
  toggleDirections = ToggleDirections;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService
  ) {
    super();
    if (this.layout.isSidebarOnly) {
      this.setHeaderInit();
    }
  }

  ngOnInit(): void {
    this.getAccountDetails();
  }

  onSidebarClosed(): void {
    if (this.layout.isSidebarOnly) {
      this.showSidebar = false;
    } else {
      this.onClose.emit(false);
    }
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }

  getCurrentUser(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.currentUser = res;
        this.setHeaderByUser();
        this.cdr.detectChanges();
      });
  }

  setHeaderByUser(): void {
    if (this.currentUser) {
      this.authService.setCurrentUser$(this.currentUser);
      if (this.currentUser.authorities.includes(Authorities.ROLE_ADMIN)) {
        this.menuItems = [...MenuItems, ...AdminUserPath];
      } else if (this.currentUser.authorities.includes(Authorities.ROLE_USER)) {
        this.menuItems = [...MenuItems, ...GeneralUserPath];
      }
      this.menuItems = [...this.menuItems, ...ContactUsPath];
      this.cdr.detectChanges();
    }
  }

  signIn(): void {
    this.navigationService.login();
  }
  signUp(): void {
    this.navigationService.signUp();
  }

  getAccountDetails(): void {
    this.authService.isLoggedIn$.subscribe((res) => {
      if (res) {
        this.getCurrentUser();
      }
    });
  }

  setHeaderInit(): void {
    if (!this.isLoggedIn) {
      this.menuItems = [...MenuItems, ...GeneralUserPath, ...ContactUsPath];
    }
  }
}
