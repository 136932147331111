import { ROUTER_PATHS } from 'src/app/shared/constants';

export const MenuItems: Array<MenuItem> = [
  {
    id: ROUTER_PATHS.dashboard.root,
    title: 'Dashboard',
    routerLink: ROUTER_PATHS.dashboard.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: []
  },
  {
    id: ROUTER_PATHS.users.root,
    title: 'Tax Credit',
    routerLink: ROUTER_PATHS.taxCredit.taxCredit,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: []
  }
];

export const ContactUsPath = [
  {
    id: ROUTER_PATHS.contactUs.root,
    title: 'Contact Us',
    isAccessible: true,
    routerLink: ROUTER_PATHS.contactUs.root
  }
];

export const GeneralUserPath: Array<MenuItem> = [
  {
    id: ROUTER_PATHS.users.root,
    title: 'History',
    isAccessible: true,
    routerLink: ROUTER_PATHS.history.root
  }
];

export const AdminUserPath: Array<MenuItem> = [
  {
    id: ROUTER_PATHS.users.root,
    title: 'Manage Tax Credit',
    isAccessible: true,
    routerLink: `${ROUTER_PATHS.admin.root}/${ROUTER_PATHS.manageTaxCredit.root}`
  }
];

export enum ToggleDirections {
  LEFT = 'left',
  RIGHT = 'right'
}

export interface MenuItem {
  id: string;
  title: string;
  icon?: string;
  routerLink?: string;
  isAccessible: boolean;
  parentRoute?: string | null;
  subMenuOptions?: Array<MenuItem>;
}
